import { Small } from "components/Typography";
import {lazy, Suspense, useState} from "react";
import {format} from "date-fns";
import ClassScheduleReportShield, {getClassScheduleStatusLabel} from "./ClassScheduleReportStatus";
import LoadingScreen from "components/LoadingScreen";
import {IconButton} from "@mui/material";
import {Check} from "@mui/icons-material";
import {ClassScheduleReportStatus} from "request/ReportRequest";
import {publish} from "components/Event";

const ClassScheduleColumnShape = [
    {
        Header: "Период",
        accessor: "period",
        Cell: ({ value }: any) => (
            <Small color="text.secondary">{
                format(new Date(value * 1000), "MM - yyyy")
            }</Small>
        ),
    },
  {
    Header: "Статус",
    accessor: "status",
    Cell: ({ value }: any) => {
      return (
          <ClassScheduleReportShield type={value} label={getClassScheduleStatusLabel(value)}/>
      );
    },
  },

  {
    Header: "Действие",
    accessor: "action",
    Cell: ({ row }: any) => {
        const [sendReport, setSendReport] = useState(false);

        const SendClassScheduleReport = lazy(() => import("../../components/SendClassScheduleReport"));

        return (
            <Suspense fallback={<LoadingScreen />}>
                <div onClick={(e) => e.stopPropagation()}>
                    <>
                        {ClassScheduleReportStatus.PENDING === row.original.status &&
                            <IconButton onClick={() => setSendReport(true)} title="Отправить отчет на проверку">
                                <Check sx={{ fontSize: 18, color: "text.disabled" }} color="success" />
                            </IconButton>
                        }
                    </>
                    <SendClassScheduleReport
                        open={sendReport}
                        onClose={() => setSendReport(false)}
                        id={row.original.id}
                        onSuccess={() => {
                            setSendReport(false);
                            publish('FRESH_CLASS_SCHEDULE_REPORT_LIST')
                        }}
                    />
                </div>

            </Suspense>
        );
    },
  },
];

export default ClassScheduleColumnShape;
