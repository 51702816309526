
import {FC} from "react";
import "./styles.css";
import {Box, Card, Grid} from "@mui/material";


const LessonsPlanReport: FC = () => {

  return <>
      <Box pt={2} pb={4}>
          <Card sx={{padding: 4}}>
              <div style={{overflow: "scroll"}}>
                  <div style={{border: '1px solid black', display: 'flex'}} className='header'>
                      <div className='header__item'>
                          #
                      </div>
                      <div className='header__item'>
                          ФИО
                      </div>
                      <div className='header__item'>
                          Телефон
                      </div>
                      <div className='header__item'>
                          <div>Понедельник</div>
                          <div className='header__sub_item'>
                              <div className='header__sub_item_col'>Время</div>
                              <div className='header__sub_item_col'>Офис</div>
                          </div>
                      </div>
                  </div>


                  {/*
                  <table style={{ border: '1px solid black', borderCollapse: 'collapse'}} className='header'>
                      <thead>
                          <tr>
                              <th className='header__item number'>№</th>
                              <th className='header__item'>ФИО</th>
                              <th className='header__item'>Телефон</th>
                              <th className='header__item'>
                                  <tr>
                                      <th colSpan={2} className='header__sub_item'>Понедельник</th>
                                  </tr>
                                  <tr>
                                      <th className='header__sub_item'>Время</th>
                                      <th className='header__sub_item'>Офис</th>
                                  </tr>
                              </th>

                              <th className='header__item'>
                                  <tr>
                                      <th colSpan={2} className='header__sub_item'>Вторник</th>
                                  </tr>
                                  <tr>
                                      <th className='header__sub_item'>Время</th>
                                      <th className='header__sub_item'>Офис</th>
                                  </tr>
                              </th>

                              <th className='header__item'>
                                  <tr>
                                      <th colSpan={2} className='header__sub_item'>Среда</th>
                                  </tr>
                                  <tr>
                                      <th className='header__sub_item'>Время</th>
                                      <th className='header__sub_item'>Офис</th>
                                  </tr>
                              </th>

                              <th className='header__item'>
                                  <tr>
                                      <th colSpan={2} className='header__sub_item'>Четверг</th>
                                  </tr>
                                  <tr>
                                      <th className='header__sub_item'>Время</th>
                                      <th className='header__sub_item'>Офис</th>
                                  </tr>
                              </th>


                              <th className='header__item'>
                                  <tr>
                                      <th colSpan={2} className='header__sub_item'>Пятница</th>
                                  </tr>
                                  <tr>
                                      <th className='header__sub_item'>Время</th>
                                      <th className='header__sub_item'>Офис</th>
                                  </tr>
                              </th>

                              <th className='header__item'>
                                  <tr>
                                      <th colSpan={2} className='header__sub_item'>Суббота</th>
                                  </tr>
                                  <tr>
                                      <th className='header__sub_item'>Время</th>
                                      <th className='header__sub_item'>Офис</th>
                                  </tr>
                              </th>

                              <th className='header__item'>
                                  <tr>
                                      <th colSpan={2} className='header__sub_item'>Воскресение</th>
                                  </tr>
                                  <tr>
                                      <th className='header__sub_item'>Время</th>
                                      <th className='header__sub_item'>Офис</th>
                                  </tr>
                              </th>

                              <th className='header__item plan'>План посещений, часы</th>
                              <th className='header__item'>Оплата</th>
                          </tr>
                      </thead>
                  </table>
                  */}


              </div>
          </Card>
      </Box>



  </>
};

export default LessonsPlanReport;
