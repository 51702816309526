import axios from "../utils/axios";
import {IExecutor} from "./ExecutorRequest";


export enum ClassScheduleReportStatus {
    PENDING  = 'pending',
    ACCEPTED = 'accepted',
    SENT     = 'sent',
    DECLINED = 'declined',
}

export interface IClassScheduleReport {
    id: string;
    executor: IExecutor;
    createdAt: string;
    period: string;
}

export interface IClassScheduleReportValues {
    customers: {[key: string]: string},
    values: {[key: string]: {
            [key: string]: {id: string, day: number, spent_time: number|null}}
        },
    period: number
}

export const fetchClassScheduleReportList = async (page: number): Promise<{ pages: number; data: IClassScheduleReport[] }> => {
    const data = await axios.get(`/frontend-api/report/class-schedule`, {
        params: { page}
    });

    return data.data;
};

export const createClassScheduleReport = async (period: string = 'current'): Promise<void> => {
    const data = await axios.post(`/frontend-api/report/class-schedule?period=${period}`);

    return data.data;
};

export const fetchReport = async (reportId: string): Promise<IClassScheduleReportValues> => {
    const data = await axios.get(`/frontend-api/report/class-schedule/${reportId}`);

    return data.data;
};

export const sendReport = async (reportId: string): Promise<IClassScheduleReport> => {
    const data = await axios.patch(`/frontend-api/report/class-schedule/${reportId}/send`);

    return data.data;
};

export const updateClassScheduleReportValue = async (reportId: string, valueId: string, value: number): Promise<void> => {
    await axios.patch(`/frontend-api/report/class-schedule/${reportId}/value/${valueId}`, { value });
};
