import {Chip, styled} from "@mui/material";
import {ClassScheduleReportStatus} from "request/ReportRequest";

const ClassScheduleReportShield = styled(Chip)<{type:ClassScheduleReportStatus}>(({ theme , type}) => {
    let backgroundColor = theme.palette.common.white;
    let title;
    switch (type) {
        case ClassScheduleReportStatus.PENDING:
            backgroundColor = theme.palette.primary.main;
            title = 'Ожидание';
            break;
        case ClassScheduleReportStatus.SENT:
            backgroundColor = theme.palette.warning.main;
            title = 'Отправлен';
            break;
        case ClassScheduleReportStatus.ACCEPTED:
            backgroundColor = theme.palette.success.main;
            title = 'Принят';
            break;
        case ClassScheduleReportStatus.DECLINED:
            backgroundColor = theme.palette.error.main;
            title = 'Отменен';
            break;

    }

    return {
        height: 25,
        borderRadius: "4px",
        color: theme.palette.common.white,
        fontSize: "1rem",
        backgroundColor,
    }
});

export const getClassScheduleStatusLabel = (value: string) => {
    switch (value) {
        case ClassScheduleReportStatus.PENDING:
            return 'Ожидание';
        case ClassScheduleReportStatus.SENT:
            return 'На рассмотрении';
        case ClassScheduleReportStatus.ACCEPTED:
            return 'Принят';
        case ClassScheduleReportStatus.DECLINED:
            return 'Отклонен';
    }
}

export default ClassScheduleReportShield;