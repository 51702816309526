import {Box, Button, ButtonBase, Divider, FormHelperText, Stack, styled} from "@mui/material";
import AppCheckBox from "components/AppCheckBox";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import { Small } from "components/Typography";
import GoogleIcon from "icons/GoogleIcon";
import AuthenticationLayout from "page-sections/authentication/AuthenticationLayout";
import React, {FC, useState} from "react";
import {useFormik} from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import {Link, useNavigate} from "react-router-dom";
import {LoadingButton} from "@mui/lab";

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  width: "100%",
  padding: 12,
  marginBottom: 16,
  borderRadius: "8px",
  fontWeight: "500",
  border: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down(454)]: { width: "100%", marginBottom: 8 },
}));

const Login: FC = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const initialValues = {
    username: "",
    password: "",
  };
  // form field value validation schema
  const validationSchema = Yup.object().shape({
    username: Yup
        .string()
        .max(9, "Неверный номер телефона")
        .min(9, "Неверный номер телефона")
        .required("Номер телефона обязательное поле"),

    password: Yup
        .string()
        .required("Обязательное поле")
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
      useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
          setLoading(true);
          try {
            await login(values.username, values.password);
            setLoading(false);
            toast.success("You registered successfully");
            navigate("/dashboard");
          } catch (error: any) {
            setError(error?.message);
            setLoading(false);
          }
        },
      });

  return (
    <AuthenticationLayout
      route="/register"
      description="Новый пользователь?"
      title="Вход в учетную запись"
      routeName='Выполните регистрацию'
    >
      <form noValidate onSubmit={handleSubmit}>
        <Stack gap={2} mt={5}>
          <FlexBox justifyContent="flex-end" position='relative' gap={2} marginTop={2}>
            <span style={{position: 'absolute', left: 0, top: '15px'}}>
               +375
            </span>
            <AppTextField
                fullWidth
                style={{marginLeft: '50px'}}
                label="Номер телефона (9 цифр)"
                placeholder='291112233'
                name="username"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
            />
          </FlexBox>


          <AppTextField
              fullWidth
              label="Пароль"
              name="password"
              type="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password || ""}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
          />
          {error && (
              <FormHelperText
                  error
                  sx={{
                    mt: 2,
                    fontSize: 13,
                    fontWeight: 500,
                    textAlign: "center",
                  }}
              >
                {error}
              </FormHelperText>
          )}

          <FlexBetween>
            <FlexBox alignItems="center" gap={1}>
              <AppCheckBox defaultChecked />
              <Small fontSize={12}>Запомнить меня</Small>
            </FlexBox>

            <Link to={'/forget-password'} style={{ color: '#FF316F', fontSize: '15px' }}>
              Восстановить пароль
            </Link>

          </FlexBetween>

          <Box sx={{ mt: 4 }}>
            {loading ? (
                <LoadingButton loading fullWidth variant="contained">
                  Войти
                </LoadingButton>
            ) : (
                <Button fullWidth type="submit" variant="contained">
                  Войти
                </Button>
            )}
          </Box>
        </Stack>
      </form>

      {/*

      <Divider sx={{ marginTop: 4 }}>
        <Small color="text.disabled" px={1}>
          OR
        </Small>
      </Divider>



      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        my={3}
      >
        <StyledButton>
          <GoogleIcon sx={{ marginRight: 1, fontSize: "1.2rem" }} />
          Signin with Google
        </StyledButton>
      </Stack>

      */}

    </AuthenticationLayout>
  );
};

export default Login;
