import {
  Button,
  Grid,
  Stack,
  FormHelperText,
  Box
} from "@mui/material";
import AuthenticationLayout from "page-sections/authentication/AuthenticationLayout";
import AppTextField from "components/input-fields/AppTextField";
import React, {FC, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
import * as Yup from "yup";
import {useFormik} from "formik";
import toast from "react-hot-toast";
import {LoadingButton} from "@mui/lab";
import CountDownTimer from "../../components/CountDownTimer";
import {Paragraph} from "../../components/Typography";
import {checkForgetPasswordRequest} from "../../request/UserRequest";

const ForgetPasswordVerifyCode: FC = () => {
  const { register } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const initialValues = {
    code: ""
  };
  // form field value validation schema
  const validationSchema = Yup.object().shape({
    code: Yup
        .string()
        .required("Обязательное поле")
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
      useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, {setErrors}) => {
          setLoading(true);
          try {
            await checkForgetPasswordRequest(location.state.code, values.code);
            setLoading(false);
            navigate("/new-password", {state: {privateCode: values.code, publicCode: location.state.code}});
          } catch (error: any) {
            setErrors({code: error.privateCode.join(', ')})
            setLoading(false);
          }
        },
      });

  return (
    <AuthenticationLayout
      route="/login"
      routeName=''
      title="Подтверждение номера телефона"
      description='На указанный номер телефон отправлен код. Введите код, чтобы установить новый пароль'
    >
      <form noValidate onSubmit={handleSubmit}>
        <Stack gap={2} mt={5}>
          <Grid container spacing={3}>

            <Grid item xs={12}>
              <CountDownTimer minutes={30} />
            </Grid>

            <Grid item xs={12}>
              <AppTextField
                  fullWidth
                  label="Код"
                  name="code"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.code || ""}
                  error={Boolean(touched.code && errors.code)}
                  helperText={touched.code && errors.code}
              />
            </Grid>

            <Grid item xs={12}>
              {error && (
                  <FormHelperText
                      error
                      sx={{
                        mt: 2,
                        fontSize: 13,
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                  >
                    {error}
                  </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <Box>
                {loading ? (
                    <LoadingButton loading fullWidth variant="contained">
                      Подтвердить
                    </LoadingButton>
                ) : (
                    <Button fullWidth type="submit" variant="contained">
                      Подтвердить
                    </Button>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}  mt={5}>
              <Paragraph color="text.disabled" fontWeight={500}>
                <Link to={'/login'}>{'Войти в систему'}</Link>
              </Paragraph>
            </Grid>
          </Grid>
        </Stack>
      </form>

    </AuthenticationLayout>
  );
};

export default ForgetPasswordVerifyCode;
