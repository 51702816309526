import {Box, Button, CircularProgress, Stack, styled} from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import CustomTable from "page-sections/admin-ecommerce/CustomTable";
import {FC, useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import useAuth from "hooks/useAuth";
import {subscribe, unsubscribe} from "components/Event";
import {useNavigate} from "react-router-dom";
import ClassScheduleColumnShape from "./components/ClassScheduleColumnShape";
import {
  createClassScheduleReport,
  fetchClassScheduleReportList,
  IClassScheduleReport
} from "../../../request/ReportRequest";

export const HeadingWrapper = styled(FlexBox)(({ theme }) => ({
  marginBottom: 20,
  flexWrap: "wrap",
  [theme.breakpoints.down(530)]: {
    "& .MuiButton-root": { width: "100%" },
    "& .MuiInputBase-root": { maxWidth: "100%", marginBottom: 15 },
  },
}));

const ClassScheduleReportList: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");

  const [pageValue, setPageValue] = useState(1);
  const handlePage = (value: number) => setPageValue(value);

  const [filteredItem, setFilteredItem] = useState<IClassScheduleReport[]>([]);
  const [countItems, setCountItems] = useState(1);
  const [loading, setLoading] = useState(false);

  const {user} = useAuth();

  const load = async () => {
    setLoading(true)
    const { data, pages } = await fetchClassScheduleReportList(pageValue);
    setFilteredItem(data);
    setCountItems(pages);
    setLoading(false)
  }

  const navigateToDetails = (rowData: object) => {
    // @ts-ignore
    return () => navigate(`/dashboard/class-schedule-report/${rowData.id}`);
  };

  const canCreateNew = useMemo(() => {
    if (loading)
      return false

    if (!filteredItem.length)
      return true

    const currentMonth = (new Date()).getMonth()
    const currentYear = (new Date()).getFullYear()

    for (let item of filteredItem) {
      const date = new Date(+item.period * 1000)

      if ((date.getMonth() == currentMonth) && (date.getFullYear() == currentYear))
        return false
    }

    return true;
  }, [loading]);

  const canCreatePrev = useMemo(() => {
    if (loading)
      return false

    if (!filteredItem.length)
      return true

    const curr = new Date();
    curr.setDate(0);

    const currentMonth = curr.getMonth()
    const currentYear = curr.getFullYear()

    for (let item of filteredItem) {
      const date = new Date(+item.period * 1000)

      if ((date.getMonth() == currentMonth) && (date.getFullYear() == currentYear))
        return false
    }

    return true;
  }, [loading]);

  useEffect(() => {
    subscribe('FRESH_CLASS_SCHEDULE_REPORT_LIST', load);

    return () => {
      unsubscribe('FRESH_CLASS_SCHEDULE_REPORT_LIST', load);
    }
  }, [searchValue]);

  useEffect(() => {
    (async () => {
      if (!user) {
        return;
      }

      load();
    })();
  }, []);

  return (
    <>
      {!loading ? <Box pt={2} pb={4}>
        <Box display='flex' gap='15px'>
          {canCreatePrev ? <Button
              variant="contained"
              color="warning"
              onClick={async () => {
                await createClassScheduleReport('prev')
                await load()
              }}
          >
            Создать отчет за предыдущий месяц
          </Button> : null}


          {canCreateNew ? <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                await createClassScheduleReport('current')
                await load()
              }}
          >
            Создать отчет за текущий месяц
          </Button> : null}
        </Box>

        {filteredItem.length ? <>
          <CustomTable
              columnShape={ClassScheduleColumnShape}
              data={filteredItem}
              rowClick={(data) => navigateToDetails(data)}
          />
        </> : <Box pt={2} pb={4}>Нет созданных отчетов</Box>}
      </Box> : <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: 1, height: "100vh" }}
      >
        <CircularProgress />
      </Stack>
      }
    </>
  );
};

export default ClassScheduleReportList;
