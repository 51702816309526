
import {FC, useState, useEffect} from "react";
import { defineCustomElements } from "@revolist/revogrid/loader";
import { RevoGrid as RevoGridComponent } from "@revolist/revogrid-react";
import "./styles.css";
import {Box, Card} from "@mui/material";
import NumberColumnType from '@revolist/revogrid-column-numeral'
import {Edition, RevoGrid} from "@revolist/revogrid/dist/types/interfaces";
import {useParams} from "react-router-dom";
import {fetchReport, IClassScheduleReportValues, updateClassScheduleReportValue} from "request/ReportRequest";
import {RevoGridCustomEvent} from "@revolist/revogrid/dist/types/components";


const valueCellProperties = () => {
  return {
    style: {
      textAlign: 'center',
      maxWidth: '100px',
      boxShadow: '-1px 0 0 #f1f1f1 inset'
    },
  };
}

const valueColumnTemplate = (createElement: any, column: any) => {
  return createElement('div', {
    style: {
      whiteSpace: 'pre-wrap',
      lineHeight: '20px',
      fontWeight: 600,
      padding: '10px 0',
      writingMode: 'vertical-rl',
      transform: 'rotate(-180deg)',
    },
  }, column.name);
}

const periodCellProperties = () => {
  return {
    style: {
      color: 'black',
      fontWeight: 600,

    },
  };
}

const getDaysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
}

const ClassScheduleReport: FC = () => {
  const [columns, setColumns] = useState<RevoGrid.ColumnRegular>()
  const [source, setSource] = useState<RevoGrid.DataType[]>()

  const {id} = useParams();
  const [report, setReport] = useState<IClassScheduleReportValues|null>(null);
  const [reportLoading, setReportLoading] = useState<boolean>(false);

  useEffect(() => {
    defineCustomElements();
  }, [])

  //Получение данных
  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }

      setReportLoading(true)
      const data = await fetchReport(`${id}`);
      setReport(data);
      setReportLoading(false)
    })();
  }, [id]);


  // Установка колонок
  useEffect(() => {
    if (reportLoading || null === report)
      return

    const columns = [];

    columns.push({
      prop: "date",
      cellProperties: periodCellProperties,
      pin: 'colPinStart',

      readonly: true
    })

    for (const [id, name] of Object.entries(report.customers)) {
      columns.push({
        prop: id,
        name: name,

        columnType: 'numeric',
        columnTemplate: valueColumnTemplate,
        cellProperties: valueCellProperties,
      })
    }

    // @ts-ignore
    setColumns(columns)
  }, [reportLoading])


  useEffect(() => {
    if (reportLoading || null === report)
      return

    const period = new Date(report.period * 1000)
    const periodMonth = period.getMonth() + 1
    const periodYear = period.getFullYear()
    const daysInMonth = getDaysInMonth(periodMonth, periodYear)

    const sources = [];


    for (const index of Array(daysInMonth).fill(1).map((x, y) => x + y)) {
      const val = {
        date: index + '.' + periodMonth + '.' + periodYear
      }

      for (const id of Object.keys(report.values)) {
        // @ts-ignore
        val[id] = report.values[id][index]['spent_time']
      }

      sources.push(val)
    }

    setSource(sources)
  }, [reportLoading])

  useEffect(() => {

  }, [])

  const onEdit = async ({detail}: RevoGridCustomEvent<Edition.BeforeSaveDataDetails | Edition.BeforeRangeSaveDataDetails>) => {
    /* @ts-ignore */
    const ceilVal = detail.val;

    /* @ts-ignore */
    const rowIndex = detail.rowIndex;

    /* @ts-ignore */
    const customerId = detail.prop;

    const item = report?.values[customerId][rowIndex+1]
    const itemId = item?.id;

    try {
      // @ts-ignore
      await updateClassScheduleReportValue(id, itemId, ceilVal)
    } catch (e) {

    }
  }

  const plugin = { 'numeric': new NumberColumnType('0,0') };

  return <>
      <Box pt={2} pb={4} height="100vh">
        <Card sx={{padding: 4}}>
          <RevoGridComponent
              theme="compact"
              /* @ts-ignore */
              columns={columns}
              source={source}
              columnTypes={plugin}
              onAfteredit={(event: RevoGridCustomEvent<Edition.BeforeSaveDataDetails | Edition.BeforeRangeSaveDataDetails>) => onEdit(event)}
          />
        </Card>
      </Box>
  </>
};

export default ClassScheduleReport;
