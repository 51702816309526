import axios from "../utils/axios";

export interface IUser {
  id: string;
  firstName: string;
  middleName: string|null;
  lastName: string;
  role: UserRole;
  email: string|null;
  phone: string|null;
  avatar: string|null;
}

export enum UserRole {
  EXECUTOR = 'executor',
  CUSTOMER = 'customer',
}

interface IAuth {
  accessToken: string;
  refreshToken: string;
}

export interface IValidation {
  [key: string]: string[];
}

export const fetchSignedIn = async (): Promise<IUser> => {
  const {data} = await axios.get("/frontend-api/signed-in-user");

  return data.data;
};

export const signIn = async (username: string, password: string): Promise<IAuth> => {
  const {data} = await axios.post("/frontend-api/sign-in", { username: '375' + username, password });

  return data;
};

export const signUp = async (publicCode: string, privateCode: string): Promise<IAuth> => {
  const {data} = await axios.post("/frontend-api/sign-up", { publicCode, privateCode });

  return data;
};

export const createSignUpRequest = async (username: string, password: string): Promise<{ code: string }> => {
  const {data} = await axios.post("/frontend-api/sign-up-request", { username: '375' + username, password });

  return data;
};

export const createForgetPasswordRequest = async (username: string): Promise<{ code: string }> => {
  const {data} = await axios.post("/frontend-api/forget-password-request", { username: '375' + username });

  return data;
};

export const checkForgetPasswordRequest = async (publicCode: string, privateCode: string): Promise<boolean> => {
  const {data} = await axios.post("/frontend-api/check-forget-password-code-request", { publicCode, privateCode });

  return data;
};

export const setNewPasswordAnonRequest = async (privateCode: string, publicCode: string, password: string, password_confirmation: string): Promise<IAuth> => {
  const {data} = await axios.post("/frontend-api/set-new-password", { publicCode, privateCode, password, password_confirmation });

  return data;
};

export const verifyCaptcha = async (captcha: string): Promise<{ success: boolean }> => {
  const {data} = await axios.post("/frontend-api/verify-captcha", { captcha });

  return data;
};

export const updateContacts = async (email?: string|null, phone?: string|null): Promise<IAuth> => {
    const {data} = await axios.patch("/frontend-api/user/update-contacts", { email, phone });

    return data;
};